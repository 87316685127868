import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout'
import privacyStyle from './privacy.module.scss'
import Head from '../components/head'

const PrivacyPage = () => {
  const {t} = useTranslation()
  return (
    <Layout>
      <Head title={t('Privacy Title')} />
      <h1 className={privacyStyle.privacyTitle}><Trans>Privacy Title</Trans></h1>
        <p className={privacyStyle.privacyText}>
        <Trans>Privacy Paragraph One</Trans>
        </p>
        <h2 className={privacyStyle.privacyTitle}><Trans>Privacy Two</Trans></h2>
        <p className={privacyStyle.privacyText}>
        <Trans>Privacy Paragraph Two</Trans>
        </p>
        <h2 className={privacyStyle.privacyTitle}><Trans>Privacy Three</Trans></h2>
        <p className={privacyStyle.privacyText}>
        <Trans>Privacy Text One</Trans><br />
        <Trans>Privacy Text Two</Trans><br />
        <Trans>Privacy Text Three</Trans><br />
        <Trans>Privacy Text Four</Trans><br />
        <Trans>Privacy Text Five</Trans><br />
        <Trans>Privacy Text Six</Trans><br />
        <Trans>Privacy Text Seven</Trans><br />
        <Trans>Privacy Text Eight</Trans><br />
        <Trans>Privacy Text Nine</Trans><br />
        <Trans>Privacy Text Ten</Trans>
        </p>
        <h2 className={privacyStyle.privacyTitle}><Trans>Privacy Four</Trans></h2>
        <p className={privacyStyle.privacyText}>
        <Trans>Privacy Paragraph Three</Trans>
        </p>
        <h2 className={privacyStyle.privacyTitle}><Trans>Privacy Five</Trans></h2>
        <p className={privacyStyle.privacyText}>
        <Trans>Privacy Paragraph Four</Trans>
        </p>
        <h2 className={privacyStyle.privacyTitle}><Trans>Privacy Six</Trans></h2>
        <p className={privacyStyle.privacyText}>
        <Trans>Privacy Paragraph Five</Trans>
        </p>
        <h2 className={privacyStyle.privacyTitle}><Trans>Privacy Seven</Trans></h2>
        <p className={privacyStyle.privacyText}>
        <Trans>Privacy Paragraph Six</Trans>
        </p>
        <h2 className={privacyStyle.privacyTitle}><Trans>Privacy Eight</Trans></h2>
        <p className={privacyStyle.privacyText}>
        <Trans>Privacy Paragraph Seven</Trans>
        </p>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;